import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import imgAbout from '../../../static/images/Toli-CV_WordCloud-NEW.jpg'
import AboutHeader from '../../StaticQueries/AboutHeader'

//import Typography from '@material-ui/core/Typography'
import Typography from 'typography'   // Gatsby Typography
import irvingTheme from 'typography-theme-irving'

const typography = new Typography(irvingTheme)


const styles = theme => ({
    paper: {
        maxWidth: 1700,
        margin: 'auto',
        overflow: 'hidden',
        [theme.breakpoints.up('sm')]: {
            minWidth: 600,
        },
        [theme.breakpoints.up('lg')]: {
            minWidth: 936,
        },
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    block: {
        display: 'block',
    },
    addUser: {
        marginRight: theme.spacing.unit,
    },
    contentWrapper: {
        margin: '40px 16px',
        // height: 368,
    },
    container: {
        padding: '48px 36px 48px',
    },
})


const AboutForm = props => (
    <div id="main" className="alt">
        <section id="one">
            <div style={{fontSize: '120%', color: 'blue'}}>
                The Software Engineering aspect of the Firm is highlighted in the
                '<b>Services</b>', '<b>Industries</b>' and '<b>Projects</b>' pages of this site, with additional
                references under the '<b>Director</b>' page, which summarizes the skills and acomplishments
                of the Director and principal software developer of the company.
                <br/><br/>
                The Music Composition/Production element of this site reflects the plan to host
                old and future work with a multitude of music compositions for Films, TV and the
                Video Games industry.
                <br/><br/>
            </div>

            <div className="inner" align="center">
                <span className="image main">
                    <img src={imgAbout} alt="About" width="80%"/>
                </span>

                <h1 style={{ color: 'green' }}>INTELLECT :: For Intelligent Business Decisions!</h1>
            </div>

            <div className="footer" align="justify" style={{fontSize: '100%', color: 'brown'}}>
                <hr/>
                <ul style={{ color: 'brown' }}>
                    <li>This is yet another GatsbyJS Web site developed with related principles
                        and with its original skeleton initially based on Marc Arbesman's "<b>Paperbase</b>" Starter Theme, 
                        but later modified to utilize classic Gatsby Typography elements.</li>
                    <li>The various logos of the businesses and academic institutions found on this site
                        have been taken from their respective Web sites or other Internet sources and
                        scaled appropriately to be inserted in the pages of the site.</li>
                    <li>The 3D outline page titles have been produced by using '<b>cooltext.com</b>' online generators.</li>
                    <li>The photo embedded into the '<b>Industries</b>' page is a public domain asset and has been
                        contributed by Owen CL and hosted on Unsplash (scaled, detail).</li>
                    <li>The photo embedded into the '<b>Projects</b>' page is a public domain asset and has been
                        contributed by Rohan Makhecha and hosted on Unsplash (scaled, detail).</li>
                    <li>The photo embedded into the '<b>Success</b>' page (which is displayed following the
                        submission of a '<b>Contact</b>' e-mail inquiry) is a public domain asset and has been
                        contributed by Markus Spiske and hosted on Unsplash.</li>
                    <li>The photo embedded into the '<b>Careers</b>' page is a public domain asset and has been
                        contributed by Marvin Meyer and hosted on Unsplash (scaled, detail).</li>
                    <li>The photo embedded into the '<b>Portfolio</b>' page is a public domain asset and has been
                        contributed by Oleg Sergeichik and hosted on Unsplash (scaled, detail).</li>
                    <li>The photo embedded into the '<b>Music/Technology</b>' page is a public domain asset
                        and has been contributed by Steve Harvey and hosted on Unsplash (detail).</li>
                    <li>The photo embedded into the '<b>RSS Feeds/SDTimes</b>' page is a public domain asset
                        and has been contributed by Pietro Jeng and hosted on Unsplash (detail).</li>
                    <li>The photo embedded into the '<b>RSS Feeds/RedditSD</b>' page is a public domain asset
                        and has been contributed by Hunter Harritt and hosted on Unsplash (detail).</li>
                    <li>The photo embedded into the '<b>RSS Feeds/Geeks4Geeks</b>' page is a public domain asset
                        and has been contributed by Margaret Weir and hosted on Unsplash (detail).</li>
                    <li>The photo embedded into the '<b>Feed Aggregators/AllTop</b>' page is a public domain asset
                        and has been contributed by Johannes Groll and hosted on Unsplash (detail).</li>
                    <li>The photo embedded into the '<b>Playground/RxJS Observables</b>' page is a public domain asset,
                        solely created by NASA, onto which the RxJS logo has been fitted.</li>
                </ul>
            </div>
        </section>
    </div>
)


function AboutContent({ classes }) {
    return (
        <>
            <div className={classes.container}>
                <Paper className={classes.paper}>

                    <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                        {/* <h1 align="center" style={{ color: 'green' }}>-= A°B°O°U°T ≡≡≡ T°H°E ≡≡≡ F°I°R°M =-</h1> */}
                        <AboutHeader/>
                    </AppBar>

                    <div className={classes.contentWrapper}>
                        {/* <Typography color="textSecondary" align="justify"> */}
                        <typography color="textPrimary" align="justify">
                            <AboutForm/>
                        {/* </Typography> */}
                        </typography>
                    </div>
                </Paper>
            </div>
        </>
    )
}

AboutContent.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AboutContent)
